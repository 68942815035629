import React, { useState, useEffect } from "react";
import logo from "../Assets/source File-1 (1).png";
import { Link } from "react-router-dom";

import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import ReadMoreRoundedIcon from "@mui/icons-material/ReadMoreRounded";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
} from "@mui/material";
import LogIn from "./LogIn";

const NavbarPhone = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State to manage drawer visibility
  const [openSublist, setOpenSublist] = useState({}); // State to manage sublist visibility
  const [isScrolled, setIsScrolled] = useState(false); // State for scroll-based styling

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open); // Set the drawer state
  };

  const toggleSublist = (index) => {
    setOpenSublist((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const menuItems = [
    { text: "Home", link: "" },
    {
      text: "Services",
      link: "",
      subItems: [
        "Implementation",
        "Development",
        "Consulting",
        "Integration",
        "Admin",
        "App Exchange Apps",
        "Data Migration",
        "Support",
        "Training",
      ],
    },
    {
      text: "Expertise",
      link: "",
      subItems: [
        "Sales Cloud",
        "Service Cloud",
        "Experience Cloud",
        "Data Cloud",
        "Marketing Cloud",
        "Loyalty Management",
        "Pardot",
        "B2B Commerce Cloud",
        "Consumer Goods Cloud",
        "FSL",
        "CPQ & Billing",
        "Financial Service Cloud",
        "Salesforce Industries",
        "Health Cloud",
        "NPSP",
        "Nintex - Drawloop Docgen",
        "Nintex - Assuresign",
        "Mulesoft",
      ],
    },
    {
      text: "Resources",
      link: "",
      subItems: [
        { caseStudy: "Marketing", title: "Case Study : Marketing" },
        { caseStudy: "Sales", title: "Case Study : Sales" },
        { caseStudy: "Health", title: "Case Study : Health" },
      ],
    },
    { text: "About Us", link: "/about-us" },
    // { text: "Careers", link: "/" },
  ];

  const handleScrollTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div
      className="header-mobile"
      style={{
        backgroundColor: isScrolled ? "white" : "transparent",
        position: "fixed",
        width: "100%",
        top: 0,
        zIndex: 10,
        transition: "background-color 0.3s ease",
        boxShadow: isScrolled ? "0px 4px 10px rgba(0, 0, 0, 0.1)" : "none",
      }}
    >
      <div
        className="elementor-container elementor-column-gap-extended"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "30px",
        }}
      >
        <div className="the-logo">
          <Link
            to="/"
            onClick={handleScrollTop}
            style={{
              outline: "none",
              textDecoration: "none",
              WebkitTapHighlightColor: "transparent", // Removes blue flash on mobile devices
            }}
            onMouseDown={(e) => e.preventDefault()}
          >
            <img src={logo} alt="UntangleIt" />
          </Link>
        </div>

        <IconButton onClick={toggleDrawer(true)} aria-label="Open menu">
          <MenuRoundedIcon fontSize="large" />
        </IconButton>

        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
        >
          <div style={{ width: 300 }}>
            <IconButton onClick={toggleDrawer(false)} aria-label="Close menu">
              <ReadMoreRoundedIcon fontSize="large" />
            </IconButton>
            <List>
              {menuItems.map((item, index) => (
                <React.Fragment key={index}>
                  <ListItem
                    button={!!item.subItems}
                    onClick={() => item.subItems && toggleSublist(index)}
                    sx={{ gap: "15px" }}
                  >
                    <Link
                      to={item.link}
                      onClick={() => {
                        if (item.link) {
                          setIsDrawerOpen(false);
                          handleScrollTop(true);
                        }
                        if (item.text === "Home") {
                          handleScrollTop(true);
                          setIsDrawerOpen(false);
                        }
                      }}
                      style={{
                        color: "inherit",
                        WebkitTapHighlightColor: "transparent",
                      }}
                    >
                      <ListItemText
                        primary={item.text}
                        sx={{ fontFamily: "inherit" }}
                      />
                    </Link>
                    <ListItemIcon>
                      {item.subItems ? (
                        openSublist[index] ? (
                          <RemoveIcon fontSize="small" />
                        ) : (
                          <AddIcon fontSize="small" />
                        )
                      ) : null}
                    </ListItemIcon>
                  </ListItem>

                  {item.subItems && (
                    <Collapse
                      in={openSublist[index]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {item.subItems.map((subItem, subIndex) => (
                          <ListItem
                            button
                            key={subIndex}
                            sx={{
                              pl: 6,
                              color: "inherit",
                              WebkitTapHighlightColor: "transparent",
                            }}
                          >
                            <Link
                              to={
                                item.text === "Resources"
                                  ? `/CaseStudy/${subItem.caseStudy}`
                                  : "#"
                              }
                              style={{
                                color: "inherit",
                                textDecoration: "none",
                              }}
                              onClick={() => {
                                if (item.text === "Resources") {
                                  setIsDrawerOpen(false);
                                  handleScrollTop(true);
                                }
                              }}
                            >
                              <ListItemText
                                primary={
                                  item.text === "Resources"
                                    ? subItem.title
                                    : subItem
                                }
                                sx={{
                                  color: "inherit",
                                  fontSize: "0.9rem",
                                  fontFamily: "inherit",
                                }}
                              />
                            </Link>
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  )}
                </React.Fragment>
              ))}
              <ListItem>
                <LogIn
                  isScrolled={isScrolled}
                  inNavbar={false}
                  closeDrawer={() => setIsDrawerOpen(false)}
                />
              </ListItem>
            </List>
          </div>
        </Drawer>
      </div>
    </div>
  );
};

export default NavbarPhone;
