import React from "react";
import { Typography, Box, Container} from "@mui/material";

const ChallengeSection = ({ challenge }) => (
  <Container maxWidth="md" sx={{ py: { xs: 2, md: 4 } }}>
    <Box>
      <Typography
        variant="h6"
        sx={{
          fontSize: { xs: "0.9rem", sm: "1.05rem", md: "1.25rem" },
          color: "inherit",
          fontFamily: "inherit",
          mb: 1,
        }}
      >
        <strong style={{ fontSize: "1.5rem", color: "#0f1a51" }}>
          Business Challenges
        </strong>{" "}
        <br />
        {challenge.description}
      </Typography>
      
      <Box
        component="ul"
        sx={{
          textAlign: { xs: "left", sm: "left" },
          listStyleType: "disc",
          paddingLeft: { xs: 2, sm: 4 },
          color: "inherit",
        }}
      >
        {challenge.details.map((detail, idx) => (
          <Typography
            key={idx}
            component="li"
            sx={{
              fontSize: { xs: "0.9rem", sm: "1.05rem", md: "1.25rem" },
              color: "inherit",
              my: 2.5,
              fontFamily: "inherit",
            }}
          >
            {detail}
          </Typography>
        ))}
      </Box>
    </Box>
  </Container>
);

export default ChallengeSection;
