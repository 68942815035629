import React, { useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import caseStudies from "../data/caseStudy";
import Hero from "./Hero";
import { Box } from "@mui/material";
import ClientSection from "./Client";
import ChallengeSection from "./Challange";
import SolutionSection from "./Sollution";
import ResultsSection from "./Result";
import TechnologyUsedSection from "./Technology";

const CaseStudy = () => {
  const { caseStudy, title } = useParams();
  const selectedCaseStudy = caseStudies.find(
    (item) =>
      item.caseStudy.toLowerCase() === caseStudy.toLowerCase() 
      //&&
      //item.title.toLowerCase() === title.toLowerCase()
  );

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [caseStudy, title]);

  if (!selectedCaseStudy) {
    return <div style={styles.notFound}>Case Study not found</div>;
  }

  return (
    <>
      <Hero title={selectedCaseStudy.title} />
      <Box
        sx={{
          px: { xs: 2, sm: 5, md: 10 },
          py: { xs: 2, sm: 4, md: 5 },
          backgroundColor: "white", // Adjust background color as needed
        }}
      >
        <ClientSection client={selectedCaseStudy.client} />
        <ChallengeSection challenge={selectedCaseStudy.challenge} />
        <TechnologyUsedSection
          technologyUsed={selectedCaseStudy.technologyUsed}
        />
        <SolutionSection solution={selectedCaseStudy.solution} />
        <ResultsSection results={selectedCaseStudy.results} />
      </Box>
    </>
  );
};

const styles = {
  notFound: {
    textAlign: "center",
    fontSize: "1.5rem",
    color: "#888",
    marginTop: "50px",
  },
};

export default CaseStudy;

