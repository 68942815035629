import React from "react";
import { Typography, Box, Container } from "@mui/material";

const ClientSection = ({ client }) => (
  <Container maxWidth="md" sx={{ py: { xs: 2, md: 4 } }}>
    <Box>
      <Typography variant="h6" sx={{ fontSize: { xs: "0.9rem",sm:"1.05rem", md: "1.25rem" }, color: "inherit", fontFamily:"inherit" }}>
        <strong style={{ fontSize: "1.5rem", color:"#0f1a51"}}>Client Overview</strong> <br/>{client}
      </Typography>
    </Box>
  </Container>
);

export default ClientSection;
