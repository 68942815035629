import React from "react";
import { Link } from "react-router-dom";

const Resources = () => {

  const caseStudy = [
    
    {
      caseStudy:"Marketing",
      
      title:"Case Studies : Marketing",
    },
    {
      caseStudy : "Sales",
      title:"Case Studies : Sales",
    },
    {
      caseStudy : "Health",
      title:"Case Studies : Health",
    },


  ];

  return (
    <>
      <Link
        to="/"
        className="dropdown-toggle"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-ps2id-api="true"
      >
        Resources
      </Link>
      <ul className="sub-menu">
        {caseStudy.map((item,index)=>(
          <li className="menu-item menu-item-type-post_type menu-item-object-page"
          key={index}>
          <Link
            to={`/CaseStudy/${item.caseStudy}`}
            className="dropdown-toggle"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-ps2id-api="true"
          >
            {item.title}
          </Link>
          
        </li>
        ))}
        
       
      </ul>
    </>
  );
};

export default Resources;
